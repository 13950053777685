import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import i18n from '../i18n/i18n'
import { getTitle } from '../util/title-helper'
import './styles/basic.css'

const Payment = ({ pageContext }) => {
  const lang = pageContext.lang
  
  useEffect(() => {
    const script = document.createElement('script');
  
    script.innerHTML = `(function(w,d,t,u,f,k,a){w[f]=w[f]||function(){(w[f].a=w[f].a||[]).push(arguments)};k=d.createElement(t),a=d.getElementsByTagName(t)[0],k.async=1,k.src=u,a.parentNode.insertBefore(k,a)})(window, document, "script", "https://kalenteri.webia.fi/static/minjs/payment_result_tag.js", "bc");bc({target: "payment_result",});`;
    script.async = true;

    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>{getTitle(lang, i18n[lang].payment.title)}</title>
      </Helmet>
      <SEO title={getTitle(lang, i18n[lang].payment.title)} lang={lang} />
      
      <div id="payment_result"></div>
    </Layout>
  )
}

export default Payment